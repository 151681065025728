
























































































































































































































































































































import {
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BRow,
  BSpinner,
  BFormSelect,
  BIcon,
  BIconPencilFill,
  VBTooltip,
} from "bootstrap-vue";
import { Component, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import ListContacts from "./Contacts/Contacts.vue";
import ListContrats from "./Contrats/Contrats.vue";
import { PartenaireFASTT } from "@/api/models/FASTT/partenaires/partenaire";
import { StatusEnum, StatusEnumNumber } from "@/api/models/enums/statusPartenaireFASTT";
import ListGuidesFonctionnement from "./guidesFonctionnement/GuidesFonctionnement.vue";
import ListDocumentsBeneficiaire from "./documentsBeneficiaire/DocumentBeneficiaire.vue";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BSpinner,
    BForm,
    BFormGroup,
    BFormRadio,
    BFormCheckbox,
    BFormSelect,
    BIcon,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
    ListContacts,
    ListContrats,
    BIconPencilFill,
    ListGuidesFonctionnement,
    ListDocumentsBeneficiaire
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
})
export default class EditPartenaireFastt extends Vue {
  statusPartenairesFASTT = StatusEnum;
  statusPartenairesFASTTNumber = StatusEnumNumber;

  partenaire: PartenaireFASTT | null = null;
  editPartenaire: PartenaireFASTT | null = null;
  
  listTypesPartenaires: any[] = [];
  listTagsFASTT : any[] = [];

  mainLoading = true;
  editMode = true;
  required = required;
  
  departements = [
    { id: 1, libelle: "01 - Ain" },
    { id: 2, libelle: "02 - Aisne" },
    { id: 3, libelle: "03 - Allier" },
    { id: 4, libelle: "04 - Alpes-de-Haute-Provence" },
    { id: 5, libelle: "05 - Hautes-Alpes" },
    { id: 6, libelle: "06 - Alpes-Maritimes" },
    { id: 7, libelle: "07 - Ardèche" },
    { id: 8, libelle: "08 - Ardennes" },
    { id: 9, libelle: "09 - Ariège" },
    { id: 10, libelle: "10 - Aube" },
    { id: 11, libelle: "11 - Aude" },
    { id: 12, libelle: "12 - Aveyron" },
    { id: 13, libelle: "13 - Bouches-du-Rhône" },
    { id: 14, libelle: "14 - Calvados" },
    { id: 15, libelle: "15 - Cantal" },
    { id: 16, libelle: "16 - Charente" },
    { id: 17, libelle: "17 - Charente-Maritime" },
    { id: 18, libelle: "18 - Cher" },
    { id: 19, libelle: "19 - Corrèze" },
    { id: 20, libelle: "20 - Corse-du-Sud" },
    { id: 21, libelle: "21 - Côte-d'Or" },
    { id: 22, libelle: "22 - Côtes-d'Armor" },
    { id: 23, libelle: "23 - Creuse" },
    { id: 24, libelle: "24 - Dordogne" },
    { id: 25, libelle: "25 - Doubs" },
    { id: 26, libelle: "26 - Drôme" },
    { id: 27, libelle: "27 - Eure" },
    { id: 28, libelle: "28 - Eure-et-Loir" },
    { id: 29, libelle: "29 - Finistère" },
    { id: 30, libelle: "30 - Gard" },
    { id: 31, libelle: "31 - Haute-Garonne" },
    { id: 32, libelle: "32 - Gers" },
    { id: 33, libelle: "33 - Gironde" },
    { id: 34, libelle: "34 - Hérault" },
    { id: 35, libelle: "35 - Ille-et-Vilaine" },
    { id: 36, libelle: "36 - Indre" },
    { id: 37, libelle: "37 - Indre-et-Loire" },
    { id: 38, libelle: "38 - Isère" },
    { id: 39, libelle: "39 - Jura" },
    { id: 40, libelle: "40 - Landes" },
    { id: 41, libelle: "41 - Loir-et-Cher" },
    { id: 42, libelle: "42 - Loire" },
    { id: 43, libelle: "43 - Haute-Loire" },
    { id: 44, libelle: "44 - Loire-Atlantique" },
    { id: 45, libelle: "45 - Loiret" },
    { id: 46, libelle: "46 - Lot" },
    { id: 47, libelle: "47 - Lot-et-Garonne" },
    { id: 48, libelle: "48 - Lozère" },
    { id: 49, libelle: "49 - Maine-et-Loire" },
    { id: 50, libelle: "50 - Manche" },
    { id: 51, libelle: "51 - Marne" },
    { id: 52, libelle: "52 - Haute-Marne" },
    { id: 53, libelle: "53 - Mayenne" },
    { id: 54, libelle: "54 - Meurthe-et-Moselle" },
    { id: 55, libelle: "55 - Meuse" },
    { id: 56, libelle: "56 - Morbihan" },
    { id: 57, libelle: "57 - Moselle" },
    { id: 58, libelle: "58 - Nièvre" },
    { id: 59, libelle: "59 - Nord" },
    { id: 60, libelle: "60 - Oise" },
    { id: 61, libelle: "61 - Orne" },
    { id: 62, libelle: "62 - Pas-de-Calais" },
    { id: 63, libelle: "63 - Puy-de-Dôme" },
    { id: 64, libelle: "64 - Pyrénées-Atlantiques" },
    { id: 65, libelle: "65 - Hautes-Pyrénées" },
    { id: 66, libelle: "66 - Pyrénées-Orientales" },
    { id: 67, libelle: "67 - Bas-Rhin" },
    { id: 68, libelle: "68 - Haut-Rhin" },
    { id: 69, libelle: "69 - Rhône" },
    { id: 70, libelle: "70 - Haute-Saône" },
    { id: 71, libelle: "71 - Saône-et-Loire" },
    { id: 72, libelle: "72 - Sarthe" },
    { id: 73, libelle: "73 - Savoie" },
    { id: 74, libelle: "74 - Haute-Savoie" },
    { id: 75, libelle: "75 - Paris" },
    { id: 76, libelle: "76 - Seine-Maritime" },
    { id: 77, libelle: "77 - Seine-et-Marne" },
    { id: 78, libelle: "78 - Yvelines" },
    { id: 79, libelle: "79 - Deux-Sèvres" },
    { id: 80, libelle: "80 - Somme" },
    { id: 81, libelle: "81 - Tarn" },
    { id: 82, libelle: "82 - Tarn-et-Garonne" },
    { id: 83, libelle: "83 - Var" },
    { id: 84, libelle: "84 - Vaucluse" },
    { id: 85, libelle: "85 - Vendée" },
    { id: 86, libelle: "86 - Vienne" },
    { id: 87, libelle: "87 - Haute-Vienne" },
    { id: 88, libelle: "88 - Vosges" },
    { id: 89, libelle: "89 - Yonne" },
    { id: 90, libelle: "90 - Territoire de Belfort" },
    { id: 91, libelle: "91 - Essonne" },
    { id: 92, libelle: "92 - Hauts-de-Seine" },
    { id: 93, libelle: "93 - Seine-Saint-Denis" },
    { id: 94, libelle: "94 - Val-de-Marne" },
    { id: 95, libelle: "95 - Val-d'Oise" },
    { id: 971, libelle: "971 - Guadeloupe" },
    { id: 972, libelle: "972 - Martinique" },
    { id: 973, libelle: "973 - Guyane" },
    { id: 974, libelle: "974 - La Réunion" },
    { id: 976, libelle: "976 - Mayotte" },
    { id: 975, libelle: "975 - Saint-Pierre-et-Miquelon" },
    { id: 977, libelle: "977 - Saint-Barthélemy" },
    { id: 978, libelle: "978 - Saint-Martin" },
    { id: 986, libelle: "986 - Wallis-et-Futuna" },
    { id: 987, libelle: "987 - Polynésie française" },
    { id: 988, libelle: "988 - Nouvelle-Calédonie" }
];

  async created() {
    await this.loadTypesPartenaires();
    await this.getPartenaireFastt();
    await this.loadParametreTag();

    if (this.$route.params.editMode)
      this.editMode = false;
  }
  
  goToList() {
    this.$router.push({ name: "partenaires-fastt" });
  }
  
  async edit() {
    this.editMode = !this.editMode;
  }
  async markAsValid(){
    this.editPartenaire!.statut = StatusEnumNumber.Valide.toString();
    await this.submit(this.editPartenaire!);
  }
  
  reset() {
    this.editPartenaire = this.partenaire;
    this.editMode = true;
  }
  
  async getPartenaireFastt() {
    this.mainLoading = true;
    await this.$http.myressif.partenairesFASTT
    .getById(this.$route.params.id)
    .then((res: PartenaireFASTT) => {
      let departementsActionTemp: any[] = res
        .departementsAction
        .map((x) => {
          return this.departements.find(y => y.id == x)
        })

      res.departementsAction = departementsActionTemp
      this.partenaire = { ...res };
      this.editPartenaire = { ...res };
    });
    this.mainLoading = false;
  }
  
  
  async loadParametreTag() {
    await this.$http.myressif.parametres
      .getByNom("tags_partenaires_fastt")
      .then((res: any) => {
        this.listTagsFASTT = res ? JSON.parse(res.valeur) : [];
      });
  }

  async loadTypesPartenaires() {
    await this.$http.myressif.typesPartenaireFASTT
        .paginatedList(1, 200)
        .then((res: any) => {
          this.listTypesPartenaires = res.items;
        });
  }
    
  async askDeletePartenaire(id: string) {
    this.$bvModal
    .msgBoxConfirm("Etes-vous sûr de vouloir supprimer ce partenaire ?", {
      title: "Confirmation de suppression",
      size: "sm",
      okVariant: "primary",
      okTitle: "Oui",
      cancelTitle: "Non",
      cancelVariant: "outline-secondary",
      hideHeaderClose: false,
      centered: true,
    })
    .then(async (value) => {
      if (value === true) {
        await this.confirmDelete(id);
      } else {
        return;
      }
    });
  }
  
  async confirmDelete(id: string) {
    await this.$http.myressif.partenairesFASTT
    .delete(id)
    .then(
    async (response: string) => {
      successAlert.fire({
        title: "Suppression d'un partenaire FASTT",
        text: "Suppression effectuée avec succès",
      });
    },
    (error: any) => {
      if (
      error?.response?.data?.errors &&
      Object.keys(error.response.data.errors).length
      ) {
        errorAlert.fire({
          text: error.response.data.errors[
          Object.keys(error.response.data.errors)[0]
          ],
        });
      } else {
        errorAlert.fire({
          text: error?.response?.data?.detail ?? error.message,
        });
      }
    },
    this.$router.push({ name: "partenaires-fastt" })
    );
  }
  
  async submit(editPartenaire: PartenaireFASTT) {
    var payload = JSON.parse(JSON.stringify(editPartenaire));
    payload.departementsAction = editPartenaire.departementsAction.map(x => x.id);


    await this.$http.myressif.partenairesFASTT
      .update(this.$route.params.id, payload)
      .then(
        async (res: any) => {
          successAlert.fire({
            title: "Modification d'un partenaire FASTT",
            text: "Modification effectuée avec succès",
          });
          await this.getPartenaireFastt();
          this.editMode = true;
      },
      (error: any) => {
        if (
        error?.response?.data?.errors &&
        Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
            Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      }
    );
  }
  
  async removeTypePartenaire(item: any) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir retirer ce type à ce partenaire ? ", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.$http.myressif.typesPartenaireFASTT
            .removeMembre(item.id, this.$route.params.id)
            .then(async (res: any) => {
                successAlert.fire({
                  title: "Ajout d'un type au partenaire FASTT",
                  text: "Modification effectuée avec succès",
                });
            }, (error: any) => {
            if (
              error?.response?.data?.errors &&
              Object.keys(error.response.data.errors).length
            ) {
              errorAlert.fire({
                text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
                ],
              });
            } else {
              errorAlert.fire({
                text: error?.response?.data?.detail ?? error.message,
              });
            }
          }
        );
        } else {
          return;
        }
      });
  }

  async addTypePartenaire(item: any){
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir lier ce partenaire a ce type ? ", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.$http.myressif.typesPartenaireFASTT
            .addMembre(item.id, this.$route.params.id)
            .then(async (res: any) => {
              successAlert.fire({
                title: "Ajout d'un type au partenaire FASTT",
                text: "Modification effectuée avec succès",
              });
            }, (error: any) => {
            if (
              error?.response?.data?.errors &&
              Object.keys(error.response.data.errors).length
            ) {
              errorAlert.fire({
                text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
                ],
              });
            } else {
              errorAlert.fire({
                text: error?.response?.data?.detail ?? error.message,
              });
            }
          }
        );
      } else {
        return;
      }
    });
  }

  getDepartementLabel(departementNumber: number) {
    return this.departements.find(x => x.id == departementNumber)!.libelle
  }
}
