







































































































import {
  BSidebar,
  BForm,
  BButton,
  BFormGroup,
  BFormInput,
  BSpinner,
  BFormFile,
  VBTooltip
} from "bootstrap-vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { DocumentBeneficiaireCreateModel } from "@/api/models/documentsBeneficiaire/documentBeneficiaire";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";

@Component({
  components: {
    BSidebar,
    BSpinner,
    BButton,
    BFormInput,
    BForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    SearchableVueSelect
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  name: "create-document-beneficiaire",
})
export default class CreateDocumentBeneficiaire extends Vue {
  @Prop({ required: true, type: Boolean })
  isAddNewDocumentBeneficiaireSidebarActive!: boolean;
  loading = false;

  required = required;

  listTypeContrat = [];
  totalCountTypeContrat = 0;

  newDocumentBeneficiaire: DocumentBeneficiaireCreateModel = {};

  async created() {
    await this.getTypeContrat();
  }

  resetForm() {
    this.newDocumentBeneficiaire = {};
  }

  async getTypeContrat() {
    await this.$http.myressif.parametres
      .getByNom("type_document_beneficiaire_partenaire_fastt")
      .then((res: any) => {
        this.listTypeContrat = res ? JSON.parse(res.valeur) : [];
      }),
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      };
  }

  async submit() {
    this.loading = true;
    await this.$http.myressif.partenairesFASTT
      .createDocumentsBeneficiairePartenaireFASTT(this.$route.params.id, this.newDocumentBeneficiaire)
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Création d'un document bénéficiaire",
            text: "Document bénéficiaire créé avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refresh-documents-beneficiaire");
        this.$emit("update:is-add-new-document-beneficiaire-sidebar-active", false);
        this.loading = false;
      });
  }
}
