



























































































































































import {
  BSidebar,
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BDropdown,
  BFormGroup,
  BDropdownItem,
  BFormInput,
  BPagination,
  BFormSelect,
  BRow,
  BTable,
  BSpinner,
  BFormDatepicker,
  BFormFile
} from "bootstrap-vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { Contrat, UpdateContratModel } from "@/api/models/contrats/contrat";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import Categories from "@/api/models/enums/categoriesEnum";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BSpinner,
    BFormDatepicker,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
  },
  name: "update-contrat",
})
export default class EditContrat extends Vue {
  @Prop({ required: true, type: Boolean })
  isUpdateContratSidebarActive!: boolean;
  @Prop({ required: true }) contratId!: string;

  loading = false;
  required = required;
  loadingContrat = false;

  listTypeContrat = []
  totalCountTypeContrat = 0

  contrat: UpdateContratModel = {};

  categories = Categories;

  isAddFileEnabled = false;

  async created() {
    await this.$http.myressif.partenairesFASTT
      .getContratById(this.$route.params.id, this.contratId)
      .then((res: Contrat) => {
        this.contrat = res;
        this.loadingContrat = true;
      });
  }

  resetForm() {
    this.contrat = {};
  }

  async submit() {
    this.loading = true;
    await this.$http.myressif.partenairesFASTT
      .updateContratPartenaireFASTT(
        this.$route.params.id,
        this.contrat.id,
        this.contrat
      )
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Modification du contrat",
            text: "Contrat modifié avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refresh-contrat");
        this.$emit("update:is-update-contrat-sidebar-active", false);
        this.loading = false;
        this.isAddFileEnabled = false;
      });
  }
}
