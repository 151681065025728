














































































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import { GuideFonctionnement } from "@/api/models/guidesFonctionnement/guideFonctionnement";
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BBadge
} from "bootstrap-vue";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { PartenaireFASTT } from "@/api/models/FASTT/partenaires/partenaire";
import UpdateGuideFonctionnement from "./UpdateGuideFonctionnement.vue";
import CreateGuideFonctionnement from "./CreateGuideFonctionnement.vue";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BBadge,
    CreateGuideFonctionnement,
    UpdateGuideFonctionnement, 
  },
})
export default class ListGuidesFonctionnement extends Vue {
  @Prop({ required: true }) partenaire!: PartenaireFASTT;
  perPage = 10;
  currentPage = 1;
  perPageOptions = [2, 5, 10, 25, 50, 100];
  isAddNewGuideFonctionnementSidebarActive = false;
  isUpdateGuideFonctionnementSidebarActive = false;
  selectedGuideFonctionnementId = "";

  tableColumns = [
      { key: "nomFichier", label: "Nom fichier" },
      { key: "description", label: "type" },
      { key: "dateDerniereModification", label: "dateDerniereModification" },
      { key: "actions", label: "actions" },
    ];
  paginatedListGuidesFonctionnement = new PaginatedList<GuideFonctionnement>();

  async created() {
    await this.loadListGuidesFonctionnement({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  get paginationFrom() {
    return (
      this.perPage * (this.currentPage - 1) +
      (this.paginatedListGuidesFonctionnement.items.length ? 1 : 0)
    );
  }
  get paginationTo() {
    return (
      this.perPage * (this.currentPage - 1) +
      this.paginatedListGuidesFonctionnement.items.length
    );
  }

  @Watch("currentPage")
  @Watch("perPage")
  async paginationChanged() {
    await this.loadListGuidesFonctionnement({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  

  selectGuideFonctionnement(guideFonctionnementId: string) {
    this.selectedGuideFonctionnementId = guideFonctionnementId;
    this.isUpdateGuideFonctionnementSidebarActive = true;
  }

  async downloadFile(adherentId: string, guideFonctionnementId: string) {
    await this.$http.myressif.partenairesFASTT
      .downloadGuideFonctionnement(adherentId, guideFonctionnementId)
      .then(
        async (response: any) => {
          window.open(response);
        },
        (error: any) => {
          if (error?.response?.data?.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
        }
      );
  }

  async loadListGuidesFonctionnement(params: any) {
    if (!params || params?.reset) {
      this.paginatedListGuidesFonctionnement = new PaginatedList<GuideFonctionnement>();
    }
    await this.$http.myressif.partenairesFASTT
      .getListGuidesFonctionnementByPartenaireFASTTId(
        this.$route.params.id,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<GuideFonctionnement>) => {
        this.paginatedListGuidesFonctionnement = response;
      });
  }

  askDelete(id: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer ce guide de fonctionnement ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(id);
        } else {
          return;
        }
      });
  }

  async confirmDelete(id: string) {
    await this.$http.myressif.partenairesFASTT
      .deleteGuideFonctionnement(this.$route.params.id, id)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Suppression d'un guide de fonctionnement",
            text: "Suppression effectuée avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.paginationChanged();
      });
  }
}
