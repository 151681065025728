





























































































































import {
  BSidebar,
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BDropdown,
  BFormGroup,
  BDropdownItem,
  BFormInput,
  BPagination,
  BFormSelect,
  BRow,
  BTable,
  BSpinner,
} from "bootstrap-vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { ContactCreateModel } from "@/api/models/contacts/contact";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import CategoriesEnum from "@/api/models/enums/categoriesEnum";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
  },
  name: "create-contact",
})
export default class CreateContact extends Vue {
  @Prop({ required: true, type: Boolean })
  isAddNewContactSidebarActive!: boolean;
  loading = false;

  required = required;
  categories = CategoriesEnum;

  newContact: ContactCreateModel = {
    categorie: "AUTRE"
  };

  resetForm() {
    this.newContact = {
      categorie: "AUTRE"
    };
  }

  async submit() {
    this.loading = true;
    await this.$http.myressif.partenairesFASTT
      .createContactOnPartenaireFASTT(this.$route.params.id, this.newContact)
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Création d'un contact",
            text: "Contact créé avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refresh-contact");
        this.$emit("update:is-add-new-contact-sidebar-active", false);
        this.loading = false;
      });
  }
}
