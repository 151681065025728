







































































































import {
  BSidebar,
  BForm,
  BButton,
  BFormGroup,
  BFormInput,
  BSpinner,
  BFormFile,
  VBTooltip
} from "bootstrap-vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { GuideFonctionnementCreateModel } from "@/api/models/guidesFonctionnement/guideFonctionnement";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";

@Component({
  components: {
    BSidebar,
    BSpinner,
    BButton,
    BFormInput,
    BForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    SearchableVueSelect
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  name: "create-guide-fonctionnement",
})
export default class CreateGuideFonctionnement extends Vue {
  @Prop({ required: true, type: Boolean })
  isAddNewGuideFonctionnementSidebarActive!: boolean;
  loading = false;

  required = required;

  listTypeContrat = [];
  totalCountTypeContrat = 0;

  newGuideFonctionnement: GuideFonctionnementCreateModel = {};

  async created() {
    await this.getTypeContrat();
  }

  resetForm() {
    this.newGuideFonctionnement = {};
  }

  async getTypeContrat() {
    await this.$http.myressif.parametres
      .getByNom("type_guide_fonctionnement_partenaire_fastt")
      .then((res: any) => {
        this.listTypeContrat = res ? JSON.parse(res.valeur) : [];
      }),
      (error: any) => {
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      };
  }

  async submit() {
    this.loading = true;
    await this.$http.myressif.partenairesFASTT
      .createGuideFonctionnementPartenaireFASTT(this.$route.params.id, this.newGuideFonctionnement)
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Création d'un guide de fonctionnement",
            text: "Guide de fonctionnement créé avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refresh-guides-fonctionnement");
        this.$emit("update:is-add-new-guide-fonctionnement-sidebar-active", false);
        this.loading = false;
      });
  }
}
