














































































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import { DocumentBeneficiaire } from "@/api/models/documentsBeneficiaire/documentBeneficiaire";
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BBadge
} from "bootstrap-vue";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { PartenaireFASTT } from "@/api/models/FASTT/partenaires/partenaire";
import UpdateDocumentBeneficiaire from "./UpdateDocumentBeneficiaire.vue";
import CreateDocumentBeneficiaire from "./CreateDocumentBeneficiaire.vue";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BBadge,
    CreateDocumentBeneficiaire,
    UpdateDocumentBeneficiaire, 
  },
})
export default class ListDocumentsBeneficiaire extends Vue {
  @Prop({ required: true }) partenaire!: PartenaireFASTT;
  perPage = 10;
  currentPage = 1;
  perPageOptions = [2, 5, 10, 25, 50, 100];
  isAddNewDocumentBeneficiaireSidebarActive = false;
  isUpdateDocumentBeneficiaireSidebarActive = false;
  selectedDocumentBeneficiaireId = "";

  tableColumns = [
      { key: "nomFichier", label: "Nom fichier" },
      { key: "description", label: "type" },
      { key: "dateDerniereModification", label: "dateDerniereModification" },
      { key: "actions", label: "actions" },
    ];
  paginatedListDocumentsBeneficiaire = new PaginatedList<DocumentBeneficiaire>();

  async created() {
    await this.loadListDocumentsBeneficiaire({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  get paginationFrom() {
    return (
      this.perPage * (this.currentPage - 1) +
      (this.paginatedListDocumentsBeneficiaire.items.length ? 1 : 0)
    );
  }
  get paginationTo() {
    return (
      this.perPage * (this.currentPage - 1) +
      this.paginatedListDocumentsBeneficiaire.items.length
    );
  }

  @Watch("currentPage")
  @Watch("perPage")
  async paginationChanged() {
    await this.loadListDocumentsBeneficiaire({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  

  selectDocumentBeneficiaire(documentBeneficiaireId: string) {
    this.selectedDocumentBeneficiaireId = documentBeneficiaireId;
    this.isUpdateDocumentBeneficiaireSidebarActive = true;
  }

  async downloadFile(adherentId: string, documentBeneficiaireId: string) {
    await this.$http.myressif.partenairesFASTT
      .downloadDocumentBeneficiaire(adherentId, documentBeneficiaireId)
      .then(
        async (response: any) => {
          window.open(response);
        },
        (error: any) => {
          if (error?.response?.data?.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
        }
      );
  }

  async loadListDocumentsBeneficiaire(params: any) {
    if (!params || params?.reset) {
      this.paginatedListDocumentsBeneficiaire = new PaginatedList<DocumentBeneficiaire>();
    }
    await this.$http.myressif.partenairesFASTT
      .getListDocumentsBeneficiaireByPartenaireFASTTId(
        this.$route.params.id,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<DocumentBeneficiaire>) => {
        this.paginatedListDocumentsBeneficiaire = response;
      });
  }

  askDelete(id: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer ce document bénéficiaire ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(id);
        } else {
          return;
        }
      });
  }

  async confirmDelete(id: string) {
    await this.$http.myressif.partenairesFASTT
      .deleteDocumentBeneficiaire(this.$route.params.id, id)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Suppression d'un document bénéficiaire",
            text: "Suppression effectuée avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.paginationChanged();
      });
  }
}
